import { ordersService } from '../_services'

export const orders = {
  namespaced: true,
  state: {
    all: [],
    ORDERS_PER_PAGE: 15,
    ordersOnPage: [],
    page: 1,
    count: 0,
    params: {},
    open: {},
    details: {}
  },
  getters: {
    pages (state) {
      return state.count !== 0 ? Math.ceil(state.count / state.ORDERS_PER_PAGE) : 1
    }
  },
  actions: {
    getAll ({ commit }) {
      commit('getAllRequest')
      ordersService.getAll().then(
        orders => commit('getAllSuccess', orders),
        error => commit('getAllFailure', error)
      )
    },
    getPage ({ commit, state }, { page, params }) {
      ordersService.getPage(page, { ...params, page_size: state.ORDERS_PER_PAGE }).then(
        data => commit('getPageSuccess', { data, page, params }),
        error => commit('getPageFailure', error)
      )
    },
    getById ({ commit }, id) {
      commit('getByIdRequest')
      if (!this.state.orders.open[id]) {
        return ordersService.getById(id).then(
          order => commit('getByIdSuccess', order),
          error => commit('getByIdFailure', error)
        )
      } else {
        commit('getByIdSuccess', this.state.orders.open[id])
      }
    },
    getByIdWithParameters ({ commit }, payload) {
      commit('getByIdRequest')
      if (payload.loadFromStorage || !this.state.orders.open[payload.id]) {
        ordersService.getById(payload.id).then(
          order => commit('getByIdSuccess', order),
          error => commit('getByIdFailure', error)
        )
      } else {
        commit('getByIdSuccess', this.state.orders.open[payload.id])
      }
    },
    create ({ dispatch, commit }, payload) {
      commit('createRequest', payload)
      const request = payload
      ordersService.create(payload).then(
        response => {
          commit('createSuccess', { request, response })
        },
        error => {
          commit('createFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    update ({ dispatch, commit }, payload) {
      commit('updateRequest', payload)
      const request = payload
      ordersService.update(payload).then(
        response => {
          commit('updateSuccess', { request, response })
          dispatch('getByIdWithParameters', {
            id: payload.id,
            loadFromStorage: true
          })
        },
        error => {
          commit('updateFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    saveScheme ({ dispatch, commit }, payload) {
      commit('saveSchemeRequest', payload)
      ordersService.saveScheme(payload.orderId, payload.dataUrl).then(
        payload => {
          commit('saveSchemeSuccess', payload)
          dispatch('getByIdWithParameters', {
            id: payload.id,
            loadFromStorage: true
          })
        },
        error => {
          commit('saveSchemeFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    saveRender ({ dispatch, commit }, payload) {
      commit('saveRenderRequest', payload)
      ordersService.saveRender(payload.orderId, payload.dataUrl).then(
        payload => {
          commit('saveRenderSuccess', payload)
          dispatch('getByIdWithParameters', {
            id: payload.id,
            loadFromStorage: true
          })
        },
        error => {
          commit('saveRenderFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    getDetails ({ dispatch, commit }, orderId) {
      commit('getDetailsRequest', orderId)
      ordersService.getDetails(orderId).then(
        payload => {
          commit('getDetailsSuccess', payload)
          dispatch('getAll')
        },
        error => {
          commit('getDetailsFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    getSettings ({ commit }) {
      commit('getSettingsRequest')
      ordersService.getSettings().then(
        orders => commit('getSettingsSuccess', orders),
        error => commit('getSettingsFailure', error)
      )
    },
    updateDeliveryMeasures ({ dispatch, commit }, payload) {
        commit('updateDeliveryMeasuresRequest', payload)
        ordersService.updateDeliveryMeasures(payload.orderId).then(
          payload => {
            commit('updateDeliveryMeasuresSuccess', payload)
            // dispatch('getByIdWithParameters', {
            //   id: payload.id,
            //   loadFromStorage: true
            // })
          },
          error => {
            commit('updateDeliveryMeasuresFailure', error)
            dispatch('alert/error', error, { root: true })
          }
        )
      },
  },
  mutations: {
    getAllRequest () {},
    getAllSuccess (state, orders) {
      state.all = orders
    },
    getAllFailure (state) {
      state.all = []
    },
    getPageSuccess (state, { data, page, params }) {
      state.ordersOnPage = data.results
      state.params = params
      state.page = page
      state.count = data.count
    },
    getPageFailure (state) {
      state.ordersOnPage = []
      state.params = {}
      state.page = 1
      state.count = 0
    },
    getByIdRequest () {},
    getByIdSuccess (state, order) {
      state.open[order.id] = order
    },
    getByIdFailure () {},
    createRequest () {},
    createSuccess (state, order) {
      state.createdOrderId = order.id
    },
    createFailure () {},
    updateRequest () {},
    updateSuccess () {},
    updateFailure () {},
    getBuyersRequest () {},
    getBuyersSuccess () {},
    getBuyersFailure () {},

    saveSchemeRequest () {},
    saveSchemeSuccess () {},
    saveSchemeFailure () {},

    saveRenderRequest () {},
    saveRenderSuccess () {},
    saveRenderFailure () {},
    getDetailsRequest () {},
    getDetailsSuccess (state, order) {
      state.details[order.orderId] = order.details
    },
    getDetailsFailure () {},
    
    getSettingsRequest () {},
    getSettingsSuccess (state, settings) {
      state.settings = settings
    },
    getSettingsFailure (state) {
      state.settings = []
    },
    updateDeliveryMeasuresRequest () {},
    updateDeliveryMeasuresSuccess () {},
    updateDeliveryMeasuresFailure () {},
  }
}
