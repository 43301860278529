import { ApiObjectService } from './api-object.service'
import { authHeader } from '../_helpers'
import config from 'config'

class OrdersService extends ApiObjectService {
  namespace = 'orders'

  saveScheme (orderId, dataUrl) {
    const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify({ data_url: dataUrl })
    }

    return fetch(
      `${config.apiUrl}/api/${this.namespace}/${orderId}/save-scheme/`,
      requestOptions
    ).then(this.handleResponse)
  }

  updateDeliveryMeasures (orderId) {
    const requestOptions = {
      method: 'GET',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }

    return fetch(
      `${config.apiUrl}/api/${this.namespace}/${orderId}/update-delivery-measures/`,
      requestOptions
    ).then(this.handleResponse)
  }

  saveRender (orderId, dataUrl) {
    const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify({ data_url: dataUrl })
    }

    return fetch(
      `${config.apiUrl}/api/${this.namespace}/${orderId}/save-render/`,
      requestOptions
    ).then(this.handleResponse)
  }

  getOfferDOCX (orderId, dataUrl) {
    const requestOptions = {
      method: 'GET',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify({ data_url: dataUrl })
    }

    return fetch(
      `${config.apiUrl}/api/${this.namespace}/${orderId}/get-offer-docx/`,
      requestOptions
    ).then(this.handleResponse)
  }

  viewOfferPDF (orderId) {
    const requestOptions = {
      method: 'GET',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }

    return fetch(
      `${config.apiUrl}/api/${this.namespace}/${orderId}/view-offer-pdf/`,
      requestOptions
    ).then(this.handleResponse)
  }

  getDetails (orderId) {
    const requestOptions = {
      method: 'GET',
      headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return fetch(
      `${config.apiUrl}/api/${this.namespace}/${orderId}/equipment-details/`,
      requestOptions
    ).then(this.handleResponse)
  }

  getSettings () {
    const requestOptions = {
      method: 'GET',
      headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return fetch(
      `${config.apiUrl}/api/settings/`,
      requestOptions
    ).then(this.handleResponse)
  }
  
}

export const ordersService = new OrdersService()
