import Vue from 'vue'
import Router from 'vue-router'
import { authService } from '../_services/auth.service'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Order = () => import('@/views/orders/Order')
const Orders = () => import('@/views/orders/Orders')
const OrderForm = () => import('@/views/orders/OrderForm')

const EquipmentItems = () => import('@/views/equipment-items/EquipmentItems')
const EquipmentItem = () => import('@/views/equipment-items/EquipmentItem')
const EquipmentItemInstallationScheme = () => import('@/views/equipment-items/EquipmentItemInstallationScheme')

const StandardProjects = () => import('@/views/standard-projects/StandardProjects')
const StandardProject = () => import('@/views/standard-projects/StandardProject')

const EquipmentParts = () => import('@/views/equipment-parts/EquipmentParts')
const EquipmentPart = () => import('@/views/equipment-parts/EquipmentPart')

const EquipmentDetails = () => import('@/views/equipment-details/EquipmentDetails')
const EquipmentDetail = () => import('@/views/equipment-details/EquipmentDetail')

const Dictionaries = () => import('@/views/dictionaries/Dictionaries')
const Dictionary = () => import('@/views/dictionaries/Dictionary')
const DictionaryEntryForm = () => import('@/views/dictionaries/DictionaryEntryForm')

const Users = () => import('@/views/users/Users')
const UserForm = () => import('@/views/users/UserForm')

const LoginForm = () => import('@/views/Login')
const Logout = () => import('@/views/Logout')
const PrintEstimate = () => import('@/views/PrintEstimate')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')

Vue.use(Router)

export const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

router.beforeEach((to, from, next) => {
  const authPages = ['/login']
  const authRequired = !authPages.includes(to.path)
  const loggedIn = authService.isLoggedIn()
  if (authRequired && !loggedIn) {
    return next('/login')
  } else if (!authRequired && loggedIn) {
    return next('/')
  }

  return next()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/orders',
      // name: 'complex-constructor',
      component: TheContainer,
      children: [
        {
          path: 'orders',
          component: Orders
        },
        {
          path: 'orders/create',
          name: 'order-create',
          component: OrderForm
        },
        {
          path: '/orders/:id',
          name: 'order',
          component: Order
        },
        {
          path: '/orders/:id/:view',
          name: 'order-view',
          component: Order
        },
        {
          path: '/equipment-items',
          name: 'Спортивные комплексы',
          component: EquipmentItems
        },
        {
          path: '/equipment-items/:code---:version',
          name: 'Спортивный комплекс',
          component: EquipmentItem
        },
        {
          path: '/standard-projects',
          name: 'Типовые проекты',
          component: StandardProjects
        },
        {
          path: '/standard-projects/:code',
          name: 'Типовой проект',
          component: StandardProject
        },
        {
          path: '/equipment-items/:code/equipment-item-technical-doc',
          name: 'Монтажная схема',
          component: EquipmentItemInstallationScheme
        },
        {
          path: '/equipment-parts',
          name: 'Детали',
          component: EquipmentParts
        },
        {
          path: '/equipment-parts/:code---:version',
          name: 'Деталь',
          component: EquipmentPart
        },
        {
          path: '/equipment-details',
          name: 'Запчасти',
          component: EquipmentDetails
        },
        {
          path: '/equipment-details/create',
          name: 'Создание запчасти',
          component: EquipmentDetail
        },
        {
          path: '/equipment-details/:code---:version',
          name: 'Запчасть',
          component: EquipmentDetail
        },
        {
          path: '/dictionaries',
          name: 'Справочники',
          component: Dictionaries
        },
        {
          path: '/dictionaries/:namespace',
          name: 'Справочник',
          component: Dictionary
        },
        {
          path: 'dictionaries/:namespace/create',
          name: 'Создание записи в справочнике',
          component: DictionaryEntryForm
        },
        {
          path: 'dictionaries/:namespace/update/:id',
          name: 'Редактирование записи в справочнике',
          component: DictionaryEntryForm
        },
        {
          path: '/users',
          name: 'Пользователи',
          component: Users
        },
        {
          path: '/users/create',
          name: 'Создание пользователя',
          component: UserForm
        },
        {
          path: '/users/update/:id',
          name: 'Редактирование пользователя',
          component: UserForm
        }
      ]
    },
    {
      path: '/login',
      name: 'LoginForm',
      component: LoginForm
    },
    {
      path: '/logout',
      name: 'Выход',
      component: Logout
    },
    {
      path: '/print/estimate/:id',
      name: 'PrintEstimate',
      component: PrintEstimate
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        }
      ]
    }
  ]
}
